import React, { Component } from "react";
import { FaBed, FaHammer, FaStore } from "react-icons/fa"
import { GiHouseKeys } from "react-icons/gi"

const ServiceList = [
    {
        icon: <FaBed />,
        title: 'Rent to Rent',
        description: 'Alquilar para realquilar',
        items: [
            '1. Elijo una zona y la estudio',
            '2. Alquilo una propiedad y la adapto según las necesidades de la zona',
            '3. Alquilo las habitaciones y saco beneficio',
        ],
        description2: '100% legal',
    },
    {
        icon: <GiHouseKeys />,
        title: 'Rent to Own',
        description: 'Alquilar con opción a compra',
        items: [
            '1. Busco una propiedad y ofrezco un precio inmejorable',
            '2. Pacto condiciones, plazos y activo subarriendo',
            '3. Al terminar el plazo compro la propiedad o cedo el contrato',
        ],
        description2: '¿Sabrías cómo hacerlo sin dinero?',
    },
    {
        icon: <FaHammer />,
        title: 'House flipping',
        description: 'Comprar, reformar y vender',
        items: [
            '1. Busco propiedades destrozadas a través de contactos y me asocio con un inversor',
            '2. Creo proyecto de reforma',
            '3. Termino la propiedad o vendo los derechos del contrato y reparto beneficios',
        ],
        description2: '¡Recuerda, la ganancia está en la compra!',
    },
    {
        icon: <FaStore />,
        title: 'Cambio de uso',
        description: 'Comprar, cambiar el uso y vender',
        items: [
            '1. Busco locales con posibilidad de cambio de uso y me asocio con un inversor',
            '2. Creo el proyecto con ayuda de arquitectos',
            '3. Termino la propiedad o vendo los derechos del contrato y reparto beneficios',
        ],
        description2: '¡Cambia las reglas!',
    }
]


class ServiceThree extends Component {
    render() {
        const { column } = this.props;
        const ServiceContent = ServiceList.slice(0, this.props.item);

        return (
            <React.Fragment>
                <div className="row">
                    {ServiceContent.map((val, i) => (
                        <div className={`${column}`} key={i}>
                            <a disabled>
                                <div className="service service__style--2" style={{ width: '100%' }}>
                                    <div className="icon">
                                        {val.icon}
                                    </div>
                                    <div className="content">
                                        <h3 className="title">{val.title}</h3>
                                        <p>{val.description}</p>
                                        <ul className="list-style--1">
                                            {val.items ? val.items.map((value) => {
                                                return (<li id='item-hacer'>{value}</li>)
                                            }) : null}
                                        </ul>
                                        <p>{val.description2 || ''}</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceThree;
