import React from 'react';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";
import TextLoop from "react-text-loop";
import HeaderThree from "../component/header/HeaderThree";
import FooterTwo from "../component/footer/FooterTwo";
import TabTwo from "../elements/tab/TabTwo";
import ContactThree from "../elements/contact/ContactThree";
import PortfolioList from "../elements/portfolio/PortfolioList";
import ServiceList from "../elements/service/ServiceList";
import BlogContent from "../elements/blog/BlogContent";
import custom from "../../public/assets/css/custom.css"
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';


const SlideList = [
    {
        textPosition: 'text-left',
        category: '¿Quieres jubilarte antes de los 40?',
        description: '',
        buttonText: '',
        buttonLink: ''
    }
]
const PortfolioLanding = () => {
    let title = 'Sobre mi',
        description = `Me llamo Valerio, tengo 28 años y me estoy quedando calvo.`,
        description1 = 'Me llamo Valerio, tengo 28 años y me estoy quedando calvo.Mi objetivo no va de hacerse millonario….O si!?Empecé a trabajar a los 19 y me di cuenta que no manda el que trabaja más duro, sino el que trabaja más inteligente.',
        description2 = `Empecé a trabajar a los 19 y pronto me di cuenta que no manda el trabajo duro, sino el inteligente. 
        Quiero jubilarme a los 40 y quiero que tú también lo hagas! `,
        description3 = '¿Qué haré para conseguirlo? ¡Te lo cuento aquí abajo!',
        description4 = ' Mi objetivo es generar un cashflow de 10.000€ pasivos al mes y un patrimonio neto de +1 millón de € pagado por otros.';
    let [desc, setDesc] = React.useState(description1)
    let [mas, setMas] = React.useState('Leer más')
    const PostList = BlogContent.slice(0, 3);
    let classCsm = `slide personal-portfolio-slider slider-paralax slider-style-3 d-flex align-items-center justify-content-center bg_image ${isMobile ? '' : 'bg_image--25'}`
    return (
        <div className="active-dark">
            <Helmet pageTitle="Valerio Tochev" />

            <HeaderThree homeLink="/" logo="symbol-dark" color="color-black" />
            {/* Start Slider Area   */}
            <div id="inicio" className="fix">
                <div className="slider-wrapper">
                    {/* Start Single Slide */}
                    {SlideList.map((value, index) => (
                        <div className={classCsm} key={index}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className={`inner ${value.textPosition}`}>
                                            {value.category ? <span>{value.category}</span> : ''}
                                            <h1 className="title nombre-title">Hola, soy Valerio Tochev<br />
                                                <TextLoop>
                                                    <span id="temas"> Resuelvo problemas</span>
                                                    <span id="temas"> Genero ingresos pasivos</span>
                                                </TextLoop>{" "}
                                            </h1>
                                            <h2 id="temas2">en el sector inmobiliario</h2>
                                            {value.description ? <p className="description">{value.description}</p> : ''}
                                            {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-primary-color" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    {/* End Single Slide */}
                </div>
            </div>
            {/* End Slider Area   */}

            {/* Start About Area */}
            <div id="sobre-mi" className="fix">
                <div className="about-area ptb--120  bg_color--1">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5">
                                    <div className="">
                                        <img className="w-100" src="/assets/images/about/valeri12.jpg" alt="About Images" />
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">{title}</h2>
                                            {
                                                isMobile ?
                                                    mas == 'Leer más' ?
                                                        <>
                                                            <p className="description">{description}</p>
                                                            <p className="description" onClick={() => {
                                                                if (mas == 'Leer más') {
                                                                    setDesc(description)
                                                                    setMas('Leer menos')
                                                                } else {
                                                                    setMas('Leer más')
                                                                    setDesc(description1)
                                                                }
                                                            }} style={{ color: 'white', cursor: 'pointer' }}>{mas}</p>
                                                        </>
                                                        :
                                                        <>
                                                            <p className="description">{description}</p>
                                                            <p className="description">{description2}</p>
                                                            <p id="especial-custom" className="description">{description4}</p>
                                                            <p className="description">{description3}</p>
                                                            <p className="description" onClick={() => {
                                                                if (mas == 'Leer más') {
                                                                    setMas('Leer menos')
                                                                } else {
                                                                    setMas('Leer más')
                                                                }
                                                            }} style={{ color: 'white', cursor: 'pointer' }}>{mas}</p>
                                                        </>

                                                    :
                                                    <>
                                                        <p className="description">{description}</p>
                                                        <p className="description">{description2}</p>
                                                        <p id="especial-custom" className="description">{description4}</p>
                                                        <p className="description">{description3}</p>
                                                    </>
                                            }

                                            {/* <p className="description">{description1}</p>
                                            <p className="description">{description2}</p>
                                            <p className="description">{description3}</p>
                                            <p className="description">{description4}</p> */}
                                        </div>
                                        <div className="row mt--30">
                                            <TabTwo tabStyle="tab-style--1" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* End About Area */}

            {/* Start Service Area  */}
            <div id="que-hago" className="fix">
                <div className="service-area creative-service-wrapper ptb--120 bg_color--5" >
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                                    <h2 className="title">¿Qué es lo que hago?</h2>
                                    {/* <p>Hola Soy Valerio Tochev</p> */}
                                </div>
                            </div>
                        </div>
                        <div className="row creative-service">
                            <div className="col-lg-12">
                                <ServiceList item="6" column="col-lg-6 col-md-6 col-sm-6 col-12 text-left" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Service Area  */}
            <div id="como-lo-hago" className="fix">
                <div className="rn-blog-area ptb--120 bg_color--1 mb-dec--30">
                    <div className="container">
                        <div className="row align-items-end">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="section-title text-center">
                                    <h2>¿Cómo lo hago?</h2>
                                    <p>Escríbeme, tomamos un café y te lo cuento</p>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="view-more-btn mt--60 mt_sm--30 text-center">
                                    <a className="rn-button-style--2 btn-solid" href="#contacto"><span>AQUÍ</span></a>
                                </div>
                            </div>
                        </div>
                        {/* <div className="row mt--60 mt_sm--40">
                            {PostList.map((value, i) => (
                                <div className="col-lg-4 col-md-6 col-12" key={i}>
                                    <div className="blog blog-style--1">
                                        <div className="thumbnail">
                                            <a href="/blog-details">
                                                <img className="w-100" src={`/assets/images/blog/blog-${value.images}.jpg`} alt="Blog Images" />
                                            </a>
                                        </div>
                                        <div className="content">
                                            <p className="blogtype">{value.category}</p>
                                            <h4 className="title"><a href="/blog-details">{value.title}</a></h4>
                                            <div className="blog-btn">
                                                <a className="rn-btn text-white" href="/blog-details">Read More</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div> */}
                    </div>
                </div>
            </div>
            {/* Start Portfolio Area */}
            <div id="portafolio" className="fix">
                <div className="portfolio-area ptb--120 bg_color--5">
                    <div className="portfolio-sacousel-inner">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                                        <h2 className="title">Portafolio</h2>
                                        <p>Aquí te muestro algunos de los resultados que he obtenido hasta el momento</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <PortfolioList styevariation="text-center mt--40" column="col-lg-4 col-md-6 col-sm-6 col-12" item="6" />
                            </div>
                            <div className="row">
                                {/* <div className="col-lg-12">
                                    <div className="view-more-btn mt--60 mt_sm--30 text-center">
                                        <a className="rn-button-style--2 btn-solid" href="/blog"><span>View More</span></a>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Portfolio Area */}

            {/* Start Blog Area */}

            {/* End Blog Area */}

            {/* Start COntact Area */}
            <div id="contacto" className="fix">
                <div className="rn-contact-area ptb--120 bg_color--1">
                    <ContactThree contactImages="/assets/images/about/about-9.jpg" contactTitle="Contacto" />
                </div>
            </div>
            {/* End COntact Area */}

            <FooterTwo />
            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

        </div>
    )
}

export default PortfolioLanding;
