import React, { Component } from "react";
import axios from 'axios'

class ContactThree extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rnName: '',
            rnEmail: '',
            rnSubject: '',
            rnMessage: '',
            rnEmailError: false,
            rnNameError: false,
            rnPhoneError: false,
            errorTelefono: 'Introduzca un número de teléfono correcto'
        };
    }
    submit = () => {
        let rnEmailError = false
        let rnNameError = false
        let rnPhoneError = false
        if (!this.state.rnEmail) {
            rnEmailError = true
        }
        if (!this.state.rnName) {
            rnNameError = true
        }
        if (!this.state.rnSubject) {
            rnPhoneError = true
        }
        this.setState({ ...this.state, rnEmailError, rnNameError, rnPhoneError })
        console.log(`here`)
        if (rnEmailError || rnNameError || rnPhoneError) {

        } else {
            const pathSvatba = 'https://project-admin-back-end.herokuapp.com/test'
            let text = `*•* Nombre: *${this.state.rnName}* \n*•* Email: *${this.state.rnEmail}* \n*•* Telefono: *${this.state.rnSubject}* \n*•* Data: *${new Date().toLocaleString()}* \n*•* Mensaje: *${this.state.rnMessage}*`
            axios.post(pathSvatba, { text: text })
            alert(`Acabas de dar un paso más hacia tu retiro`)
            this.setState({
                rnName: '',
                rnEmail: '',
                rnSubject: '',
                rnMessage: '',
            })
        }
    }
    render() {
        return (
            <div className="contact-form--1">
                <div className="container">
                    <div className="row row--35 align-items-start">
                        <div className="col-lg-6 order-2 order-lg-1">
                            <div className="section-title text-left mb--50">
                                <h2 className="title">{this.props.contactTitle}</h2>
                                <p className="description">Rellena el cuestionario para ponerte en contacto conmigo o hazlo a través del siguiente correo
                                    electrónico:
                                    <a href="mailto:info@valeriotochev.com"> info@valeriotochev.com</a> </p>
                            </div>
                            <div className="form-wrapper">
                                {/* <form> */}
                                {this.state.rnNameError ?
                                    <lable style={{ color: 'red' }}>*Obligatorio</lable>
                                    : null}
                                <label htmlFor="item01">
                                    <input
                                        type="text"
                                        name="name"
                                        id="item01"
                                        value={this.state.rnName}
                                        onChange={(e) => { this.setState({ rnName: e.target.value }); }}
                                        placeholder="Nombre y apellidos *"
                                    />
                                </label>
                                {this.state.rnEmailError ?
                                    <lable style={{ color: 'red' }}>*Obligatorio</lable>
                                    : null}

                                <label htmlFor="item02">
                                    <input
                                        type="text"
                                        name="email"
                                        id="item02"
                                        value={this.state.rnEmail}
                                        onChange={(e) => { this.setState({ rnEmail: e.target.value }); }}
                                        placeholder="Correo electrónico *"
                                    />
                                </label>
                                {this.state.rnPhoneError ?
                                    <lable style={{ color: 'red' }}>*{this.state.errorTelefono}</lable>
                                    : null}
                                <label htmlFor="item03">
                                    <input
                                        type="text"
                                        name="subject"
                                        id="item03"
                                        value={this.state.rnSubject}
                                        onChange={(e) => {
                                            let num = e.target.value
                                            if (num.length == 0) {
                                                this.setState({ rnPhoneError: false })
                                            } else if (num.length < 9) {
                                                this.setState({ rnPhoneError: true })
                                            } else {
                                                this.setState({ rnPhoneError: false })
                                            }
                                            this.setState({ rnSubject: e.target.value });
                                        }}
                                        placeholder="Número de teléfono *"
                                    />
                                </label>
                                <label htmlFor="item04">
                                    <textarea
                                        type="text"
                                        id="item04"
                                        name="message"
                                        value={this.state.rnMessage}
                                        onChange={(e) => { this.setState({ rnMessage: e.target.value }); }}
                                        placeholder="Motivo del contacto"
                                    />
                                </label>
                                <button onClick={() => { this.submit() }} className="rn-button-style--2 btn-solid" id="mc-embedded-subscribe">ENVIAR</button>
                                {/* </form> */}
                            </div>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2">
                            {/* <div className="thumbnail mb_md--30 mb_sm--30">
                                <img src={`${this.props.contactImages}`} alt="trydo" />
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default ContactThree;